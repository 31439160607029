const labelKey= document.currentScript.getAttribute('labelKey');
const brandKey= document.currentScript.getAttribute('brandKey');

if(labelKey && brandKey && basicInfo) {
    window._smartico_language = basicInfo.lang.toLowerCase();
    if(basicInfo.player){
        window._smartico_user_id = basicInfo.player.id;
    }

    (function(d, r, b, h, s){h=d.getElementsByTagName('head')[0];s = d.createElement('script');s.onload=b;s.src=r;h.appendChild(s);})
    (document, 'https://libs.smartico.ai/smartico.js', function() {
        _smartico.init(labelKey, { brand_key: brandKey });

        _smartico.on('props_change', function(props) {
            if (props.core_inbox_unread_count !== undefined) {
                document.querySelectorAll('.xpush-alert').forEach(xpushElement => {
                    if(!xpushElement.querySelector('.xtreme-push-trigger__number')) {
                        let triggerNumberElement = document.createElement('span');
                        triggerNumberElement.className = 'xtreme-push-trigger__number';
                        triggerNumberElement.setAttribute('data-value', '');
                        triggerNumberElement.style.display = 'none';
                        xpushElement.appendChild(triggerNumberElement);
                    }
                });
                if(props.core_inbox_unread_count > 0) {
                    $('.xtreme-push-trigger__number').attr('data-value', props.core_inbox_unread_count);
                    $('.xtreme-push-trigger__number').css('display', 'block');
                } else {
                    $('.xtreme-push-trigger__number').attr('data-value', 0);
                    $('.xtreme-push-trigger__number').css('display', 'none');
                }
            }
        });
    });
}
